@import "variables";

.table-dashboard-flex-fixed {
  @include font-main;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  &>.card {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    &>* {
      flex: 0 1 auto;
      &.card-body {
        flex: 1 1 auto;
        display: flex;

        .react-bs-table-container {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          &>.react-bs-table {
            flex: 1 1 auto;
            display: flex;
            &>.react-bs-container-header {
              flex: 0 1 auto;
            }
            &>.react-bs-container-body {
              flex: 1 1 1px;
              min-height: 20em;
            }
          }
          &>.react-bs-table-pagination {
            flex: 0 1 auto;
          }
        }
      }
    }
  }

  .card {
    &-header {

      &:not(.inner-card) {
        font-size: 11px;
        background-color: $white;
        border-bottom: none;
      }

      .row {
        margin-left: -10px;
        margin-right: -10px;
      }

      .row > div {
        padding: 2px 5px;
      }

      .btn {
        font-size: 10px;
      }

      .input-group {
        .dropdown-menu {
          font-size: 10px;
        }
      }

      .Select {
        font-size: 10px;

        .Select-control {
          height: 28px;

          .Select-placeholder {
            line-height: 28px;
          }

          .Select-input {
            height: 26px;

            input {
              padding: 5px 0;
            }
          }
        }
      }
    }

    &-body {

      &:not(.inner-card) {
        padding: 10px 0;
        overflow: scroll;
      }

      .table {
        thead {

          th {
            font-size: 11px;
            color: $midnight;
            border: none;
            background-color: $silver;
            position: sticky;
            top: 0;
            padding: 2px;
          }
        }

        tbody {
          font-size: 11px;

          .badge {
            width: 48px;
            border-radius: 2px;
            font-size: 10px;
            padding: 3px 0;

            &:hover {
              cursor: pointer;
              background-color: $font-badge-green;
              border: 1px solid $font-badge-green;
            }
          }

          tr {
            &:hover {
              cursor: pointer;
              background-color: $border-table-list;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);

              td {
                border-top: 1px solid $border-table-list;
              }
            }
          }
        }
      }
    }

    .react-bootstrap-table-pagination {
      font-size: 12px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 15px;

      .react-bootstrap-table-pagination-total {
        padding: 0 10px;
      }
    }

    .react-bs-table-pagination {
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .react-bs-table-pagination {
    &>.row {
      &>div:nth-of-type(2) {
        display: flex !important;
        justify-content: flex-end;
        align-items: center;
        .react-bootstrap-table-page-btns-ul {
          float: none;
          margin: auto 0 !important;
        }
      }
    }
    
  }
}

.table-dashboard {
  @include font-main;

  .card {
    &-header {
      font-size: 11px;
      background-color: $white;
      border-bottom: none;

      .row {
        margin-left: -10px;
        margin-right: -10px;
      }

      .row > div {
        padding: 2px 5px;
      }

      .btn {
        font-size: 10px;
      }

      .input-group {
        .dropdown-menu {
          font-size: 10px;
        }
      }

      .Select {
        font-size: 10px;

        .Select-control {
          height: 28px;

          .Select-placeholder {
            line-height: 28px;
          }

          .Select-input {
            height: 26px;

            input {
              padding: 5px 0;
            }
          }
        }
      }
    }

    &-body {
      padding: 10px 0;
      height: 600px;
      overflow: scroll;

      @media (max-width: 1198.98px) {
        height: 450px;
      }

      .table {
        thead {

          th {
            font-size: 11px;
            color: $midnight;
            border: none;
            background-color: $silver;
            position: sticky;
            top: 0;
          }
        }

        tbody {
          font-size: 11px;

          .badge {
            width: 48px;
            border-radius: 2px;
            font-size: 10px;
            padding: 3px 0;

            &:hover {
              cursor: pointer;
              background-color: $font-badge-green;
              border: 1px solid $font-badge-green;
            }
          }

          tr {
            &:hover {
              cursor: pointer;
              background-color: $border-table-list;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);

              td {
                border-top: 1px solid $border-table-list;
              }
            }
          }
        }
      }
    }

    .react-bootstrap-table-pagination {
      font-size: 12px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 15px;

      .react-bootstrap-table-pagination-total {
        padding: 0 10px;
      }
    }

    .react-bs-table-pagination {
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .react-bootstrap-table {
      overflow-x: scroll;

      @media (max-width: 1198.98px) {
        height: 300px;
      }
    }

    .react-bs-table {
      overflow-x: scroll;
      height: 450px !important;

      @media (max-width: 1198.98px) {
        height: 300px;
      }
    }
  }
}
