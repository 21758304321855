@mixin font-main {
  font-family: 'Work Sans', 'Sarabun', sans-serif !important;
}

$white: #fff;
$whisper: #eee;
$midnight: #283228;
$silver: #f6f6f6;
$green-tea: #5cb85c;
$mint: #4baea0;
$warm-red: #ff4d4d;
$salamander: #ff6b6b;
$urine-yellow: #ffff1a;
$peach-tea: #ffcdab;
$organic: #79bac1;
$border-badge-gray: #cecece;
$font-badge-green: #01676b;
$border-border-gray: #cecece;
$font-color-form-search: #7d7d7d;
$smoky-gray: #e8e8e8;
$cloud: #f2f2f2;
$border-table-list: #f6f5f6;
$primary: #20a8d8;
$accent: #d65e9e;
$medium-gray: #aeaeae;
$deep-sea: #111d5e;
$black: #000;
$dark-blue: #1b262c;
$warm-warning: #fceea7;
$sunflower: #ffba5a;
$grey: #666666;
$alabaster: #fafafa;
$alice-blue: #f4fcff;
$chat-comment: rgb(205, 222, 255,0.3);
$whom-msg:#7F8487;

//Modal
$bg-heading-modal: #f1f1f1;
$font-label-modal: #7d7d7d;
$font-detail-label-modal: #5f6769;
$color-heading-info: #e59900;
$font-color-edit: #535353;

// Text
@mixin text-md {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

@mixin text-sm {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
}

@mixin text-xs {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
}

@mixin close-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  top: 4px;

  width: 48px;
  height: 48px;
  padding: 0;
  margin: 0;

  transition: all 0.2s ease-in-out;

  border-radius: 500px;
  border: 1px solid $whisper !important;

  & > span {
    position: relative;
    top: -2px;
  }

  &:hover {
    border-color: $black !important;
  }
}

// Can be use for button also
@mixin badge-status($border, $background, $font) {
  background-color: $background;
  border: 1px solid $border;
  color: $font;
}

@mixin set-btn-status($color) {
  background-color: $color;
  border-color: $color;
  span {
    color: $white !important;
    font-weight: bold;
  }
}

@mixin set-text-status($color) {
  color: $white;
  font-weight: bold;
  background-color: $color;
  padding: 4px;
  font-size: 10px;
  border-radius: 4px;
  line-height: 27px;
}

//Utility
.margin-2px {
  margin: 2px;
}

// Specific
$ready_for_prepress :#639a67;
$work_in_progress :#3797a4;
$production :#cf7500;
$pending :#dbdbdb;
$wait_for_feedback : #c9485b;
$wait_for_new_artwork : #d1cebd;
$checking_out_artwork : #142850;
$checking_in_artwork : #655c56;
$fetching_artwork : #0c7b93;
$proofing : #9399ff;
$waiting_for_artwork : #c9485b;
$processing_action : #9656a1;
$sending_to_production : #ff8e6e;
$creating_onlineproof : #821752;
$cancelled : #c70d3a;
$proof_confirmation : #59405c;
$ready_for_design : #C2BC56;
$ready_for_promo : #646FD4;
$promo_work_in_progress : #8CC0DE;
$wait_promo_feedback : #97C4B8;
$waiting_questionnaire_answer : #FF0090;
$design_work_in_progress : #F15412;


//order view
$font-order-view-title: #6e7c7c;
$border-order-view-info: #f4f4f2;
$btn-update: #e26a2c;
$pending-status: #cf0000;
$card-header: #f4f4f2;
$account-btn: #17706e;
$link-view: #fb7813;
$btn-change-deadline: #9b0000;
$tag: #d06224;
$submit-message: #34be82;

//Complaint

$font-title: #414141;
$bd-attach: #afb9c8;
$date-comment: #207e82;
$sender: #000;
$btn-save: #24a19c;
$btn-save-hover: #35858b;
$btn-edit: #ffc900;
$btn-edit-hover: #ffbd35;
$btn-create-complaint: #086e7d;
$post-btn: #1597e5;
$inactive-status: #ff0000;
$active-status: #6ecb63;
$disable-select: #e4e7ea;
$close-tab: #c9ccd5;
$delete-attac: #eb3349;
$bg-attach: #f5f5f5;


// Single job  (Brochure)

$border-table-bc : #F1F1F1;
$btn-send-job : #FEB139;
$sendjob-ready-status: #5BB318;
$sendjob-unready-status: #EB1D36;
$table-unready : #B2B1B9;
$confirm-send-hover : #E2703A;
$cancel-send : #BBBBBB;
$badge-items-send : #FEC260;
$send-job-btn : #0AA1DD;
$send-job-btn-hover : #0096FF;
$modal-header-bg: #F9F9F9;

$table-quotation-file-border: #eee;
$bg-item-spec: #EAF6F6;


$send-dg-proof-btn : #EF5B0C;
$view-order-proof-btn : #FEB139; 
$mkp: #B2C8DF;
$ov: #A2B5BB;
$pp: #94B49F;
$proof-cancel: #F32424;
$bg-form-search: #F9F9F9;


$bg-modal: #F9F9F9;
$btn-lock: #eee;
