@import "variables";

button.btn-back {
  background-color: $white;
  border-color: $deep-sea;
  color: $deep-sea;
  width: 80px;

  &:hover {
    background-color: $deep-sea;
    color: $white;
  }
}

button.btn-save {
  border-color: $mint;
  color: $white;
  background-color: $mint;
  font-weight: 600;
  float: right;

  &:hover {
    color: $white;
    background-color: $mint;
  }
}
