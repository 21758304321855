// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
// Open Sans
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700&family=Work+Sans&family=Inter:wght@400;500;600;700&display=swap');

html, body {
  font-family: "Inter", "Sarabun", sans-serif;
}
.sidebar .nav-link .badge{
  margin-left:2px;
  font-weight: normal;
}

table {
  tr {
    td {
      &:hover,
      &:active,
      &:focus {
        outline: none;
        cursor: pointer;
      }
    }
  }
}



.modal-open {
  // Kill the scroll on the body
  overflow: auto !important;

  .modal {
    overflow-x: auto !important;
    overflow-y: auto !important;
  }
}

.breadcrumb {
  padding: 12px;
  margin-bottom: 3px;
}