.app {
  &-body {
    .main {
      display: flex;
      flex-direction: column;
      &>* {
        flex: 0 1 auto;
        &.container-fluid {
          flex: 1 1 auto;
          padding: 0 10px;
          display: flex;
          &>* {
            flex: 1;
          }
        }
      }
    }

    .sidebar {
      .nav {
        &-link {
          .badge {
            &-custom-rush {
              background-color: #6f42c1;
            }
            &-custom-design {
              background-color: #C2BC56;
            }
            &-new_artwork {
              background-color: #d1cebd;
              color: #000;
            }
            &-wait_artwork {
              background-color: #c9485b;
            }
            &-design-wait-questionaire {
              background-color: #ff0090;
            }
          }


        }
      }

      .nav-link {
        &.active {
          //padding-left: 31px;
          background-color: #31087B ;

          &:hover {
            background-color: #20a8d8;
          }
        }
      }


      .third-child{
        &.nav-link {
          // padding-left: 38px;
        }
      }

      .sub-menu {
        .nav-link {
          padding-left: 33px;
        } 
      }
    }
  }
}

.table-data-loading {
  position: relative;
  &::before {
      content: 'Loading...';
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background-image: url('../assets/img/spinners/ggp_loading_100x67.gif');
      background-color: #fff;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 999;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      padding-top: 100px;
  }
} 

.react-bs-table-container, .react-bs-table {
  display: flex;
  flex-flow: column;
}
.react-bs-table-container {
  .react-bs-container-body, .react-bs-table {
    flex-grow: 2;
  }
  .react-bs-container-footer, .react-bs-container-header {
    overflow: visible;
  }
}

.prepress-data {
  .prepress {
    .prepress-button-list {
      button {
        max-width: unset;
        margin-right: 0.5rem;
      }
    }
  }
}

.order-tab-container, .tab-complaint > div {
  display: flex;
  flex-flow: column;
  &>.tab-content {
    flex: 1 1 auto;
    display: flex;
    &>.tab-pane.active {
      flex: 1 1 auto;
      .table-dashboard-flex-fixed {
        height: 100%;
      }
    }
  }
}
#aws-login-container {
  height: 600px;
  width: 1000px;
  position: absolute;
  left: 50%;
  top: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template: repeat(11, 1fr) / repeat(11, 1fr);
  .aws-login-cell {
    width: 100%;
    height: 100%;

    @for $i from 0 to 11 {
      &:nth-child(11n + #{$i + 1}):hover ~ #aws-login-button {
        --positionX: #{$i};
      }
      &:nth-child(n + #{11 * $i + 1}):nth-child(-n + #{11 * ($i + 1)}):hover ~ #aws-login-button {
        --positionY: #{$i};
      }
    }

    &:hover ~ #aws-login-button::before {
      filter: none;
    }
    
  }
}

#aws-login-button {
  position: absolute;
  left: 50%;
  top: 50%;
  --positionX: 4;
  --positionY: 6;

  transform:
    translate(-50%, -50%)
    perspective(800px)
    rotateY(calc(var(--positionX) * 3deg - 16.5deg))
    rotateX(calc(var(--positionY) * -3deg + 16.5deg));
  transition: transform .5s ease 0s;
  border-radius: 4px;
  box-shadow:
    rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
    rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;

  padding: 8em 5em 2em 5em;
  font-size: 28px;
  color:         #000;
  text-align:    center;
  outline: none;
  border: none;
  font-weight: bold;

  &:hover, &:focus, &:active {
    transform:
      translate(-50%, calc(-50% - 0em))
      perspective(800px);
  }

  &.clicked {
    transition: transform 0.5s ease 0s, opacity 0.5s ease 0s;
    opacity: 0;
    transform:
      translate(-50%, calc(-50% - 0em))
      perspective(800px)
      scale(2)
      ;
  }

  &:not(:active):not(:hover):not(:focus)::before {
    filter: grayscale(100%);
  }

  &::before {
    content: '';
    display: flex;
    position: absolute;
    width: 160px;
    height: 110px;
    background-image: url('../assets/img/brand/gogoprint-logo.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 160px 110px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 1em);
  }

  img {
    width: 25px;
    margin-left: 3px;
  }
 }

 .monitoring {
  width: 180px;
  background: #fff;
  padding: 14px 20px 21px 20px;
  border-radius: 10px;
  display: block;
   img {
    width: 100%;
    animation: beat .95s infinite alternate;
    transform-origin: center;
   }

   span {
    font-size: 12px;
    color: #000;
    font-weight: 600;
   }
 }

/* Heart beat animation */
@keyframes beat{
  to { transform: scale(0.89); }
}