// Here you can add other styles

tr.active {
  background: #20a8d8 !important;
  color: white !important;
}

.preflight-container {
  position: relative;

  .preflight {
    .container {
      padding: 1rem;

      #files {
        margin: 1rem;
      }
    }

    .preview-img {
      top: 0;
    }
  }
}

.gang-table {
  .no_pending {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #4dbd74;
    font-size: 1rem;
  }

  font-size: 0.5rem;

  .table-responsive {
    height: 10rem;
  }
}

.completed {
  background: #4dbd74;
  color: white;
}

.inProgress {
  background: #ffc107;
  color: white;
}

.checkpoint {
  background: #20a8d8;
  color: white;
}

.activeFormContainer {
  width: 100%;
  height: 100%;
  display: block !important;
  background-color: rgba(0, 0, 0, 0.5);

  .activeForm {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    display: flex;

    .card {
      max-height: 80%;
      width: 100%;

      .card-body {
        max-height: 100%;
        overflow: scroll;
      }
    }
  }
}

.switchTable {
  tbody {
    font-size: 0.5rem;
  }
}
