@import "variables";

.data-table {
  .table {
    thead {
      th {
        padding: 5px 12px;
        font-size: 11px;
        color: $midnight;
        border: none;
        background-color: $peach-tea;
      }
    }

    tbody {
      font-size: 11px;

      td {
        padding: 5px 12px;
      }
    }
  }
}

.react-bs-table-pagination .row div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .show-order-total {
    order: 2;
  }
}
