@import "variables";

.modal_detail {
  font-family:
    'Open Sans',
    sans-serif;
  font-size: 12px;

  .modal-header,
  .modal-footer {
    background-color: $bg-heading-modal;
  }

  .modal-body {
    input,
    select {
      font-family:
        'Open Sans',
        sans-serif;
      font-size: 12px;
      color: $font-detail-label-modal;
    }
  }
}

